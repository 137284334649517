export enum BrowseProvider {
  GOOGLE = 'google_retail_v2',
  LOOP54 = 'loop54_v2',
  NAME = 'browse_provider',
}

export enum CLPContentSource {
  CONTENTSTACK = 'contentstack',
  MONONOTH = 'mononoth',
  NAME = 'clp_content_source',
}

export enum SearchPersonalisation {
  NAME = 'search_personalisation',
  PERSONALISED = 'personalised',
  UNPERSONALISED = 'unpersonalised',
}
