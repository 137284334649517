import type { FeatureFlags } from '@noths/polaris-client-feature-flags';
import {
  buildFeatureFlagsString,
  FEATURE_FLAGS_HTTP_HEADER_NAME,
} from '@noths/polaris-client-feature-flags';
import type { DeliveryZoneCode } from '@noths/polaris-client-localisation';
import { fetchJSONWithTimeout, isBrowser } from '@noths/polaris-client-utils';
import type { CurrencyCode } from '@noths/polaris-dev-ts-types';
import getConfig from 'next/config';
import type { ParsedUrlQueryInput } from 'querystring';
import qs from 'querystring';

import type { NextConfig } from 'src/types/nextConfig';
import { logger } from 'src/utils/serverLogger';
import type { BrowseDataAPIResponseBody } from './types/BrowseDataAPIResponseBody';
const { publicRuntimeConfig } = getConfig() as NextConfig;

export const TIMEOUT = {
  server: 5500,
  browser: 15000,
};

const getTimeout = () => (isBrowser() ? TIMEOUT.browser : TIMEOUT.server);

const getBrowseApiEndpoint = () =>
  isBrowser() ? publicRuntimeConfig.endpoints.browse : publicRuntimeConfig.endpoints.browseMesh;

type BrowseProvider = 'google' | 'loop54';

export type BrowseApiPath =
  | 'listings/v1/category'
  | 'listings/v1/partner'
  | 'listings/v1/products'
  | 'listings/v1/search';

export interface FetchBrowseDataArgs {
  browseProvider?: BrowseProvider;
  cookie?: string;
  currencyCode: CurrencyCode;
  deliveryZone?: DeliveryZoneCode;
  featureFlags?: FeatureFlags;
  loggedInUserId?: string;
  previewing?: boolean;
  query?: ParsedUrlQueryInput;
  requestIsLoadTest?: boolean;
  servingConfig?: 'default_browse' | 'default_search' | 'default_search_personalised';
  shortcode?: string;
  userId?: string;
}

export const fetchBrowseData = async (
  path: BrowseApiPath,
  {
    browseProvider,
    cookie,
    currencyCode,
    deliveryZone,
    featureFlags,
    loggedInUserId,
    previewing,
    query,
    requestIsLoadTest = false,
    servingConfig,
    shortcode,
    userId,
  }: FetchBrowseDataArgs,
) => {
  const querystring = qs.encode({
    ...query,
    currencyCode,
    ...(deliveryZone ? { deliveryZone } : {}),
    ...(loggedInUserId ? { loggedInUserId } : {}),
    ...(previewing ? { previewing } : {}),
    ...(shortcode ? { shortcode } : {}),
    ...(userId ? { userId } : {}),
  });

  const apiResponse = (await fetchJSONWithTimeout(
    `${getBrowseApiEndpoint()}${path}?${querystring}`,
    {
      credentials: 'include',
      redirect: 'manual',
      timeout: getTimeout(),
      headers: {
        ...(cookie && { Cookie: cookie }),
        ...(featureFlags && {
          [FEATURE_FLAGS_HTTP_HEADER_NAME]: buildFeatureFlagsString(featureFlags),
        }),
        ...(requestIsLoadTest && { 'use-webclient': 'true' }),
        ...(browseProvider && {
          ...{
            provider: browseProvider,
          },
        }),
        ...(servingConfig && { 'vaisr-serving-config': servingConfig }),
      },
    },
  )) as Response;
  const { status } = apiResponse;

  switch (status) {
    case 200: {
      const data: BrowseDataAPIResponseBody = await apiResponse.json();

      return { data };
    }
    case 301: {
      const redirectUrl = new URL(apiResponse.headers.get('location') || '');

      return { redirectUrl: `${redirectUrl.pathname}${redirectUrl.search}` };
    }
    case 404: {
      logger.warn(`data not found in browse-data-api`, {
        path: `${path}?${querystring}`,
        querystring,
      });
      return null;
    }
    default: {
      const { errorCode, errorMessage } = await apiResponse.json();

      logger.error('error fetching data from browse-data-api', {
        path: `${path}?${querystring}`,
        status,
        errorCode,
        errorMessage,
      });

      throw new Error(`error fetching data from browse-data-api`);
    }
  }
};
