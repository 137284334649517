import { getFeatureFlags } from '@noths/polaris-client-feature-flags';
import type { IncomingMessage } from 'http';

import { BrowseProvider, CLPContentSource, SearchPersonalisation } from './featureFlags';

export const getFeatureFlagsWithMatchers = (req: IncomingMessage) => {
  return getFeatureFlags(req, {
    collapseFlagNames: [
      { newName: BrowseProvider.NAME, oldNameMatcher: /^browse_provider/m },
      { newName: CLPContentSource.NAME, oldNameMatcher: /^clp_content_source/m },
      { newName: SearchPersonalisation.NAME, oldNameMatcher: /^search_personalisation/m },
    ],
  });
};
